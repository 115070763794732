
import { $gettext, $ngettext } from 'vue-gettext';
import { byKey, Order } from '@/utils/array';
import { defineComponent, ref, watch } from 'vue';
import { currentLanguage, currentLanguageISOString, getTranslated } from '@/translation';
import { flatten, KeyOf } from '@/utils/object';
import { translatedUnitEnumeration } from '@/utils/string';
import { actionTooltip, Header } from '@/components/table/BpTable';
import { useOperatingSystemStore, OperatingSystem, Version, Designation } from '@/stores/operatingSystem';
import { useRouter } from 'vue-router';
import BpToast from '@/components/toast/BpToasts';
import useCompactMode from '@/compositions/use-compact-mode';
import { textColor } from '@/utils/color';

export default defineComponent({
  name: 'admin.operating-system-overview',
  setup() {
    ///-------------------------------------------------------------------
    /// STORE
    ///-------------------------------------------------------------------

    const store = useOperatingSystemStore();

    ///-------------------------------------------------------------------
    /// ROUTER
    ///-------------------------------------------------------------------

    const router = useRouter();

    ///-------------------------------------------------------------------
    /// APPEARANCE
    ///-------------------------------------------------------------------
    
    const { current: compactMode } = useCompactMode();

    ///-------------------------------------------------------------------
    /// HEADERS
    ///-------------------------------------------------------------------

    const headers = ref<Header[]>([]);
    watch(() => [currentLanguage.value], () => {
      headers.value = [
        {
          _id: 'name',
          name: $gettext('Name'),
          width: '2fr',
          icon: (operatingSystem: OperatingSystem) => operatingSystem.doc_type === 'operating_system_version' ? ['far', 'v'] : ['far', 'compact-disc'],
          transform: (operatingSystem: OperatingSystem) => operatingSystem.doc_type === 'operating_system_version' ? `${operatingSystem.name}${operatingSystem.alias ? ' <em>("' + operatingSystem.alias + '")</em>' : ''}` : operatingSystem.name,
        },
        {
          _id: 'color',
          name: $gettext('Color'),
          transform: (operatingSystem: OperatingSystem) => {
            const color = operatingSystem.doc_type === 'operating_system_designation' ? operatingSystem.color : (store.getById(operatingSystem.parent_id) as Designation).color;
            return `<div style="
              background: ${color}7F;
              color: ${textColor(color)};
              box-sizing: content-box;
              width: 100%;
              height: 100%;
              margin: calc(-1 * ${compactMode?.value ? 'var(--padding-sm)' : 'var(--padding)'});
              padding: ${compactMode?.value ? 'var(--padding-sm)' : 'var(--padding)'};
            ">${color}</div>`;
          },
        },
      ];
    }, { immediate: true })

    ///-------------------------------------------------------------------
    /// UPDATE
    ///-------------------------------------------------------------------

    function editOperatingSystem(id: string | string[]) {
      if (Array.isArray(id)) {
        id = id[0];
      }
      const operatingSystem = store.getById(id);
      if (!operatingSystem) {
        return;
      }
      const isVersion = operatingSystem.doc_type === 'operating_system_version';
      router.push({
        name: isVersion ? 'admin.operating-system.version.edit' : 'admin.operating-system.designation.edit',
        params: {
          id
        }
      });
    }

    ///-------------------------------------------------------------------
    /// DELETE CONFIRMATION
    ///-------------------------------------------------------------------

    const confirmation = ref(false);
    const confirmDelete = ref<{
      designations: Designation[],
      versions: Version[],
    }>({
      designations: [],
      versions: []
    });
    const confirmSelectedIds = ref<string[]>([]);

    function showDeleteConfirmation(ids: string | string[]) {
      if (!Array.isArray(ids)) {
        ids = [ids];
      }
      confirmSelectedIds.value = ids;
      const operatingSystems = store.getFlatSubtree(ids);
      confirmDelete.value.designations = (operatingSystems.filter(operatingSystem => operatingSystem.doc_type === 'operating_system_designation') as Designation[])
        .sort(byKey('name'));
      confirmDelete.value.versions = (operatingSystems.filter(operatingSystem => operatingSystem.doc_type === 'operating_system_version') as Version[])
        .sort((a, b) => store.getName(a) < store.getName(b) ? -1 : 1);
      confirmation.value = true;
    }

    ///-------------------------------------------------------------------
    /// DELETE
    ///-------------------------------------------------------------------

    async function deleteDownload() {
      const designationIDs = confirmDelete.value.designations.map(designation => designation._id);
      const versionIDs = confirmDelete.value.versions.map(version => version._id);
      const response = await store.delete([
        ...designationIDs,
        ...versionIDs,
      ]);

      if (response?.success) {
        const designations = response.data?.filter(doc => doc.doc_type === 'operating_system_designation') || [];
        const versions = response.data?.filter(doc => doc.doc_type === 'operating_system_version') || [];

        if (designations && versions) {
          if (designations.length === designationIDs.length && versions.length === versionIDs.length) {
            BpToast.show({
              color: 'green',
              title: $gettext('%{list} successfully deleted', { list: translatedUnitEnumeration({
                designations: [designationIDs.length, designations.length],
                versions: [versionIDs.length, versions.length],
              }, { includeCount: false }) }),
              content: $ngettext(
                '<strong>%{list}</strong> was successfully deleted.',
                '<strong>%{list}</strong> were successfully deleted.',
                designations.length + versions.length,
                {
                  list: translatedUnitEnumeration({
                    designations: [designationIDs.length, designations.length],
                    versions: [versionIDs.length, versions.length],
                  })
                }),
              icon: 'check',
            });
          } else {
            BpToast.show({
              color: 'red',
              title: $gettext('Failed to delete %{list}', { list: translatedUnitEnumeration({
                designations: [designationIDs.length, designations.length],
                versions: [versionIDs.length, versions.length],
              }, { difference: true }) }),
              content: [
                $ngettext(
                  '<strong>%{list}</strong> was successfully deleted.',
                  '<strong>%{list}</strong> were successfully deleted.',
                  designations.length + versions.length,
                  {
                    list: translatedUnitEnumeration({
                      designations: [designationIDs.length, designations.length],
                      versions: [versionIDs.length, versions.length],
                    })
                  }),
                $gettext(
                  'Failed to delete <strong>%{list}</strong>.',
                  {
                    list: translatedUnitEnumeration({
                      designations: [designationIDs.length, designations.length],
                      versions: [versionIDs.length, versions.length],
                    }, { difference: true })
                  }),
              ].join('<br>'),
              icon: 'xmark',
            });
          }
        } else {
          BpToast.show({
            color: 'rose',
            title: 'You should not see this!',
            content: 'Something went terribly wrong!',
            icon: 'triangle-exclamation',
          });
        }
      } else {
        BpToast.show({
          color: 'red',
          title: $gettext('Failed to delete %{list}', { list: translatedUnitEnumeration({
            designations: designationIDs.length,
            versions: versionIDs.length,
          }, { includeCount: false }) }),
          content: $gettext('Failed to delete <strong>%{list}</strong>.', { list: translatedUnitEnumeration({
            designations: designationIDs.length,
            versions: versionIDs.length,
          }) }),
          icon: 'xmark',
        });
      }
      confirmDelete.value = {
        designations: [],
        versions: [],
      };
      confirmation.value = false;
    }

    ///-------------------------------------------------------------------
    /// DRAGGING
    ///-------------------------------------------------------------------

    function dragenter(targetId: string, sourceId: string) {
      document.querySelectorAll('[data-table-row-id]').forEach(el => el.classList.remove('bp-operating-system-overview__highlight'))
      if (targetId !== sourceId && store.getValidParentDesignations(sourceId).map(designation => designation._id).includes(targetId)) {
        const el = document.querySelector(`[data-table-row-id="${targetId}"]`) as HTMLElement;
        el.classList.add('bp-operating-system-overview__highlight');
      }
    }

    function dragend(targetId: null, sourceId: string) {
      if (targetId !== sourceId) {
        const el = document.querySelector(`[data-table-row-id="${targetId}"]`) as HTMLElement;
        el.classList.remove('bp-operating-system-overview__highlight');
      }
    }

    function drop(parent: string, id: string) {
      // Remove highlight styling
      document.querySelectorAll('[data-table-row-id]').forEach(el => el.classList.remove('bp-operating-system-overview__highlight'))

      // Move versions
      const current = store.getById(id);
      if (parent !== id && store.getValidParentDesignations(id).map(designation => designation._id).includes(parent) && current && current.parent_id !== parent) {
        store.move(id, parent)
      }
    }

    ///-------------------------------------------------------------------
    /// SORTING
    ///-------------------------------------------------------------------

    const sortKey = ref<KeyOf<OperatingSystem>>('name');
    const sortOrder = ref<Order>('desc');

    function sort(sorting: Map<keyof OperatingSystem, Order>) {
      const [key, order] = sorting.entries().next().value;
      sortKey.value = key;
      sortOrder.value = order;
    }

    ///-------------------------------------------------------------------
    /// EXPANDED
    ///-------------------------------------------------------------------

    const expanded = ref<Set<string>>();

    watch(() => store.isLoading(), () => {
      if (!store.loaded || store.isLoading() || expanded.value) { return }
      expanded.value = new Set(store.getDesignations().map(designation => designation._id))
    }, { immediate: true });

    function updateExpanded(value: Set<string>) {
      expanded.value = value;
    }

    ///-------------------------------------------------------------------
    /// QUERY
    ///-------------------------------------------------------------------

    function getQuery(selection: Set<string>) {
      const id = selection.size === 1 ? Array.from(selection)[0] : undefined;
      if (!id) {
        return {};
      }
      return store.isDesignation(id) ? { parent: id } : {}
    }

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      actionTooltip,
      confirmation,
      confirmDelete,
      confirmSelectedIds,
      currentLanguageISOString,
      deleteDownload,
      dragend,
      dragenter,
      drop,
      expanded,
      flatten,
      getQuery,
      getTranslated,
      headers,
      showDeleteConfirmation,
      sort,
      sortKey,
      sortOrder,
      store,
      translatedUnitEnumeration,
      editOperatingSystem,
      updateExpanded,
    }
  }
});
